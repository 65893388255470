import scroll from 'core/scroll'
import each from 'lodash/each'
import resize from 'helpers/resize'

export default class Header {
  constructor (el) {
    this.el = el

    scroll.instance().on(this.scroll)
    this.menu = this.el.querySelector('.menu')
    this.menu.addEventListener('click', this.toggle)

    this.scrollers = this.el.parentNode.querySelectorAll('a[data-scroller]')
    each(this.scrollers, scroller => scroller.addEventListener('click', this.scrollTo))
  }

  barVisible = false
  menuOpened = false;

  scrollTo = (event) => {
    event && event.preventDefault()
    const eventTarget = event.currentTarget
    const target = document.querySelector(eventTarget.getAttribute('href'))

    if (!target) return

    const scrollTop = (target.getBoundingClientRect().top + scroll.scrollTop()) - 30
    const maxScroll = document.scrollingElement.scrollHeight - resize.height()
    scroll.scrollTo(Math.max(0, Math.min(maxScroll, scrollTop)), 800, 'easeInOutSine')
  }

  toggle = (event) => {
    this.menuOpened = !this.menuOpened
    this.menu.classList.toggle('opened', this.menuOpened)
  }

  scroll = () => {
    const { scrollTop } = scroll
    const needBarVisible = scrollTop() > 250

    if (needBarVisible !== this.barVisible) {
      document.body.classList.toggle('bar-visible', needBarVisible)
      this.barVisible = needBarVisible
    }

    if (this.menuOpened) this.toggle()
  }

  flush () {
    this.menu.removeEventListener('click', this.toggle)
    this.menu = null
    scroll.instance().off(this.scroll)
  }

  resize () {}
}
