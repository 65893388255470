import Page from 'navigation/SuperPage'
import scroll from 'core/scroll'
import resize from 'helpers/resize'

class Home extends Page {
  show (callback, previousPage) {
    if (previousPage === 'Project') {
      const el = this.el.querySelector('#projects')
      if (el) {
        const scrollTop = (el.getBoundingClientRect().top + scroll.scrollTop()) - (this.header.el.offsetHeight + 20)
        const maxScroll = document.scrollingElement.scrollHeight - resize.height()
        scroll.scrollTo(Math.max(0, Math.min(maxScroll, scrollTop)), 0)
      }
    }

    super.show(...arguments)
  }
}

Home.pageName = 'Home'

export default Home
