/* global Config */
import Navigo from 'navigo'

const buildRoot = lang => Config.baseurl
// const buildRoot = lang => Config.baseurl + '/' + lang + '/'

const router = new Navigo(buildRoot(Config.lang))
router.path = () => window.location.href
router.uri = () => window.location.pathname

if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'

window.updateLang = (lang) => {
  Config.lang = lang
  router.root = buildRoot(Config.lang)
  router.lastRouteResolved().url = ''
}

const history = []

router.hooks({
  after: (params) => history.push(router.uri())
})

router.history = () => history
router.lastPage = () => history.length > 1 ? history[history.length - 2] : false

export default router
